/*
 * ***
 * Begin 51吃瓜网官网 Javascript Utilities
 * ***
 */

/**
 * Function to extract the hash value from a file path.
 * @param {string} filePath - The path to the file with the content hash in the path.
 * @return {string} The extracted hash value.
 */
export function getContentHashFromPath(filePath) {
	let hash;
	const regex = /\/[^/]+\.([a-f0-9]+)\.[^/]+$/;
	const match = filePath.match(regex);

	if (match) {
		hash = match[1];
	}
	return hash;
}

/**
 * Function to save data in the local storage.
 * @param {string} key  - The key under which the data will be stored in local storage.
 * @param {any}    data - The data to be saved.
 */
export function saveLocalStorage(key, data) {
	localStorage.setItem(key, data);
}

/**
 * Function to retrieve data from local storage.
 * @param {string} key - The key associated with the data in local storage.
 * @return {any} The data retrieved from local storage, or null if not found.
 */
export function getLocalStorage(key) {
	const localStorageData = localStorage.getItem(key);
	return localStorageData ? JSON.parse(localStorageData) : null;
}

/**
 * Validates the dataToValidate against the value stored in local storage with the provided localStorageKey.
 * @param {string} dataToValidate  - The data to be validated.
 * @param {string} localStorageKey - The key associated with the value in local storage to validate against.
 * @return {boolean} true if the data matches what is stored in local storage, false otherwise.
 */
export function validateLocalStorage(dataToValidate, localStorageKey) {
	const localStorageData = localStorage.getItem(localStorageKey);

	if (localStorageData === dataToValidate) {
		return true;
	}
	return false;
}

/*
 * ***
 * End 51吃瓜网官网 Javascript Utilities
 * ***
 */
